// import Vue from 'vue'
// import Vuex from 'vuex'

// // Modules
// import themeConfig from './theme-config'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     themeConfig,
//   },
//   strict: process.env.DEV,
// })
import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import themeConfig from './theme-config'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    themeConfig,
  },
  strict: process.env.DEV,
  state: {
    notifications: [], // Store notifications
  },
  mutations: {
    ADD_NOTIFICATION(state, notification) {
      console.log('Notification added:', notification);
      state.notifications.push(notification); // Add notification to the state
      console.log(state.notifications)
    }
  },
  actions: {
    addNotification({ commit }, notification) {
      commit('ADD_NOTIFICATION', notification); // Trigger mutation to add a new notification
    }
  },
  getters: {
    getNotifications: state => state.notifications, // Get notifications
  }
})

// import Vue from 'vue'
// import Vuex from 'vuex'

// // Modules
// import themeConfig from './theme-config'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   modules: {
//     themeConfig,
//   },
//   strict: process.env.DEV,
//   state: {
//     notifications: [], // Store notifications
//   },
//   mutations: {
//     ADD_NOTIFICATION(state, notification) {
//       // Add new notification with a seen status set to false
//       state.notifications.push({ ...notification, seen: false });
//     },
//     MARK_AS_SEEN(state, notificationId) {
//       // Mark a specific notification as seen
//       const notification = state.notifications.find(n => n.id === notificationId);
//       if (notification) {
//         notification.seen = true;
//       }
//     }
//   },
//   actions: {
//     addNotification({ commit }, notification) {
//       commit('ADD_NOTIFICATION', notification);
//     },
//     markNotificationAsSeen({ commit }, notificationId) {
//       commit('MARK_AS_SEEN', notificationId);
//     }
//   },
//   getters: {
//     getNotifications: state => state.notifications,
//     getUnreadNotifications: state => state.notifications.filter(n => !n.seen),
//   }
// });
