import Vue from 'vue'
import VueRouter from 'vue-router'
import dashboards from '../navigation/dashboards';
import policedashboard from '../navigation/policedashboard';

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // MAIN
  //  Admin  Login code  here 
    {
      path: "/",
      name: "login",
      component: () => import("../view/pages/authentication/login/index.vue"),
      meta: {
        layout: "full",
      },
    },
   
    {
      path: "/dashboards",
      name: "dashboards-ecommerce",
      component: () => import("@/view/main/dashboards/ecommerce/index.vue"),
      meta: {
        requiresAuth: true, // This route requires authentication
      },
    },
    
  {
    path: '/policedashboard',
    name: "policedashboard-ecommerce",
    component: () => import('@/view/main/policedashboard/ecommerce/index.vue'), 
    meta: {
      requiresAuth: true,
    }
  },   
  
  // Woss  code  start 
  // Request code  here 

     {
      path: "/request/list",
      name: "ListRequest",
      component: () => import("../view/components/Request/ListRequest.vue"),
      meta: {
        requiresAuth: true, // This route requires authentication
      },
    },

    {
      path: "/request/edit/:id",
      name: "EditRequest",
      component: () => import("../view/components/Request/EditRequest.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    {
      path: "/request/view/:id",
      name: "ViewRequest",
      component: () => import("../view/components/Request/ViewRequest.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    {
      path: "/view/all/vehicle/:id",
      name: "ViewAllVehicle",
      component: () => import("../view/components/Request/ViewAllVehicle.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
  // user  code  here 
  {
    path: "/view/list/user",
    name: "ListUser",
    component: () => import("../view/components/User/ListUser.vue"),
    meta: {
      requiresAuth: true, 
    },
  },

  {
    path: "/view/user/:id",
    name: "ViewUser",
    component: () => import("../view/components/User/ViewUser.vue"),
    meta: {
      requiresAuth: true, 
    },
  },
  {
    path: "/edit/user/:id",
    name: "EditUser",
    component: () => import("../view/components/User/EditUser.vue"),
    meta: {
      requiresAuth: true, 
    },
  },
  // Police  code  here 
  {
    path: "/add/police",
    name: "AddPolice",
    component: () => import("../view/components/Police/AddPolice.vue"),
    meta: {
      requiresAuth: true, 
    },
    }, 
    {
      path:  "/list/police",
      name: "ListPolice",
      component: () => import("../view/components/Police/ListPolice.vue"),
      meta: {
        requiresAuth: true, 
      },
    },

  
    {
      path: "/view/police/:id",
      name: "ViewPolice",
      component: () => import("../view/components/Police/ViewPolice.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    // .......... 
    {
      path: "recover/vehicle/:id",
      name: "RecoverVehicle",
      component: () => import("../view/components/Vehicle/RecoverVehicle.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    {
      path: "/recover/vehicle/police/:id",  // Make sure to include leading slash
      name: "RecoverVehicleForPolice",
      component: () => import("../view/components/Vehicle/RecoverVehicleForPolice.vue"),
      meta: {
          requiresAuth: true, 
      },
  },  

    // .............................. 
    {
      path: "/edit/police/:id",
      name: "EditPolice",
      component: () => import("../view/components/Police/EditPolice.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    // Recover  Theft  Vehicle  code  here 
    {
      path:  "/recover/vehicle/theft/list",
      name: "RecoverVehicleListTheft",
      component: () => import("../view/components/Vehicle/RecoverVehicleListTheft.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    // Vehicle code  here 
    {
      path: "/add/vehicle",
      name: "AddVehicle",
      component: () => import("../view/components/Vehicle/AddVehicle.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/vehicle",
        name: "ListVehicle",
        component: () => import("../view/components/Vehicle/ListVehicle.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/vehicle/:id",
        name: "ViewVehicle",
        component: () => import("../view/components/Vehicle/ViewVehicle.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/vehicle/:id",
        name: "EditVehicle",
        component: () => import("../view/components/Vehicle/EditVehicle.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      // Brand code  here 
    {
      path: "/add/brand",
      name: "AddBrand",
      component: () => import("../view/components/Brand/AddBrand.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/brand",
        name: "ListBrand",
        component: () => import("../view/components/Brand/ListBrand.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/brand/:id",
        name: "ViewBrand",
        component: () => import("../view/components/Brand/ViewBrand.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/brand/:id",
        name: "EditBrand",
        component: () => import("../view/components/Brand/EditBrand.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
        // Category code  here 
    {
      path: "/add/category",
      name: "AddCategory",
      component: () => import("../view/components/Category/AddCategory.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/category",
        name: "ListCategory",
        component: () => import("../view/components/Category/ListCategory.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/category/:id",
        name: "ViewCategory",
        component: () => import("../view/components/Category/ViewCategory.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/category/:id",
        name: "EditCategory",
        component: () => import("../view/components/Category/EditCategory.vue"),
        meta: {
          requiresAuth: true, 
        },
      },

           // Model code  here 
    {
      path: "/add/model",
      name: "AddModel",
      component: () => import("../view/components/Model/AddModel.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/model",
        name: "ListModel",
        component: () => import("../view/components/Model/ListModel.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/model/:id",
        name: "ViewModel",
        component: () => import("../view/components/Model/ViewModel.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/model/:id",
        name: "EditModel",
        component: () => import("../view/components/Model/EditModel.vue"),
        meta: {
          requiresAuth: true, 
        },
      },



        // Theft code  here  
      {
        path: "/list/theft",
        name: "ListTheft",
        component: () => import("../view/components/Vehicle/ListTheft.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/theft/:id",
        name: "ViewTheft",
        component: () => import("../view/components/Vehicle/ViewTheft.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/theft/:id",
        name: "EditTheft",
        component: () => import("../view/components/Vehicle/EditTheft.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      // Theft Vehicle  code  for  police admin panel 
      {
        path: "/list/theft/vehicle",
        name: "ListTheftPolice",
        component: () => import("../view/components/Vehicle/ListTheftPolice.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/police/all/notification",
        name: "PoliceAllNotification",
        component: () => import("../view/components/Vehicle/PoliceAllNotification.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/recover/list/police/vehicle",
        name: "RecoverVehicleListPolice",
        component: () => import("../view/components/Vehicle/RecoverVehicleListPolice.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      
      {
        path: "/view/theft/police/:id",
        name: "ViewTheftPolice",
        component: () => import("../view/components/Vehicle/ViewTheftPolice.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
  


          // Insurance Comapny code  here 
    {
      path: "/add/insurance/company",
      name: "AddInsurance",
      component: () => import("../view/components/Insurance/AddInsurance.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/insurance/company",
        name: "ListInsurance",
        component: () => import("../view/components/Insurance/ListInsurance.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/insurance/:id",
        name: "ViewInsurance",
        component: () => import("../view/components/Insurance/ViewInsurance.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/insurance/:id",
        name: "EditInsurance",
        component: () => import("../view/components/Insurance/EditInsurance.vue"),
        meta: {
          requiresAuth: true, 
        },
      },


       // MarketPlace  code  here 
  {
    path: "/add/marketplace",
    name: "AddMarketPlace",
    component: () => import("../view/components/MarketPlace/AddMarketPlace.vue"),
    meta: {
      requiresAuth: true, 
    },
    }, 
    {
      path: "/list/marketplace",
      name: "ListMarketPlace",
      component: () => import("../view/components/MarketPlace/ListMarketPlace.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
  
    {
      path: "/view/marketplace/:id",
      name: "ViewMarketPlace",
      component: () => import("../view/components/MarketPlace/ViewMarketPlace.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    {
      path: "/edit/marketplace/:id",
      name: "EditMarketPlace",
      component: () => import("../view/components/MarketPlace/EditMarketPlace.vue"),
      meta: {
        requiresAuth: true, 
      },
    },

     // Notification  code  here 
    {
      path: "/notification/show/:id", // Add a dynamic ID parameter to the route
      name: "DetailNotificationPage",
      component: () => import("../view/components/Notification/DetailNotificationPage.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    {
      path: "/notification/show/police/:id", // Add a dynamic ID parameter to the route
      name: "PoliceNotificationDetailPage",
      component: () => import("../view/components/Notification/PoliceNotificationDetailPage.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    // ............................. 
    {
      path: "/notification/detail", // Add a dynamic ID parameter to the route
      name: "ListNotification",
      component: () => import("../view/components/Notification/ListNotification.vue"),
      meta: {
        requiresAuth: true, 
      },
    },
    
        // Slider code  here 
        {
        path: "/add/slider",
        name: "AddSlider",
        component: () => import("../view/components/Slider/AddSlider.vue"),
        meta: {
          requiresAuth: true, 
        },
        }, 
        {
          path: "/list/slider",
          name: "ListSlider",
          component: () => import("../view/components/Slider/ListSlider.vue"),
          meta: {
            requiresAuth: true, 
          },
        },
        {
          path: "/view/silder/:id",
          name: "ViewSlider",
          component: () => import("../view/components/Slider/ViewSlider.vue"),
          meta: {
            requiresAuth: true, 
          },
        },
        {
          path: "/edit/slider/:id",
          name: "EditSlider",
          component: () => import("../view/components/Slider/EditSlider.vue"),
          meta: {
            requiresAuth: true, 
          },
        },
           // Branch code  here 
    {
      path: "/add/branch",
      name: "AddBranch",
      component: () => import("../view/components/Branch/AddBranch.vue"),
      meta: {
        requiresAuth: true, 
      },
      }, 
      {
        path: "/list/branch",
        name: "ListBranch",
        component: () => import("../view/components/Branch/ListBranch.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/branch/:id",
        name: "ViewBranch",
        component: () => import("../view/components/Branch/ViewBranch.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/branch/:id",
        name: "EditBranch",
        component: () => import("../view/components/Branch/EditBranch.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
                 // Schedule code  here 
    
      {
        path: "/list/schedule",
        name: "ListSchedule",
        component: () => import("../view/components/Schedule/ListSchedule.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/view/schedule/:id",
        name: "ViewSchedule",
        component: () => import("../view/components/Schedule/ViewSchedule.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
      {
        path: "/edit/schedule/:id",
        name: "EditSchedule",
        component: () => import("../view/components/Schedule/EditSchedule.vue"),
        meta: {
          requiresAuth: true, 
        },
      },
            
    // .......................... 
    {
      path: "/main/widgets/cards/advance",
      name: "advance",
      component: () => import("@/view/main/widgets/cards/advance"),
      meta: {
        title: "Advance",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          },
          {
            text: "YodaCards"
          }
        ]
      },
    },
    {
      path: "/main/widgets/cards/statistics",
      name: "statistics",
      component: () => import("@/view/main/widgets/cards/statistics"),
      meta: {
        title: "Statistics",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          },
          {
            text: "YodaCards"
          }
        ]
      },
    },
    {
      path: "/main/widgets/cards/analytics",
      name: "analytics",
      component: () => import("@/view/main/widgets/cards/analytics"),
      meta: {
        title: "Analytics",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          },
          {
            text: "YodaCards"
          }
        ]
      },
    },
    {
      path: "/main/widgets/charts",
      name: "charts",
      component: () => import("@/view/main/widgets/charts"),
      meta: {
        title: "Charts",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          }
        ]
      },
    },
    {
      path: "/main/widgets/illustration-set",
      name: "illustration-set",
      component: () => import("@/view/main/widgets/illustration-set"),
      meta: {
        title: "Illustration Set",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          }
        ]
      },
    },
    {
      path: "/main/widgets/crypto-icons",
      name: "crypto-icons",
      component: () => import("@/view/main/widgets/crypto-icons"),
      meta: {
        title: "Crypto Icons",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          }
        ]
      },
    },
    {
      path: "/main/widgets/user-icons",
      name: "user-icons",
      component: () => import("@/view/main/widgets/user-icons"),
      meta: {
        title: "User Icons",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          }
        ]
      },
    },
    {
      path: "/main/widgets/flags",
      name: "flags",
      component: () => import("@/view/main/widgets/flags"),
      meta: {
        title: "Flags",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Widgets"
          }
        ]
      },
    },
    {
      path: "/main/layouts/divider",
      name: "divider",
      component: () => import("@/view/main/layouts/divider"),
      meta: {
        title: "Divider",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Layouts"
          }
        ]
      },
    },
    {
      path: "/main/layouts/grid-system",
      name: "grid-system",
      component: () => import("@/view/main/layouts/grid-system"),
      meta: {
        title: "Grid System",
        breadcrumb: [
          {
            text: "Main"
          },
          {
            text: "Layouts"
          }
        ]
      },
    },
    {
      path: "/main/layouts/page-layouts/boxed-layout",
      name: "boxed-layout",
      component: () => import("@/view/main/layouts/page-layouts/boxed-layout"),
    },
    {
      path: "/main/layouts/page-layouts/vertical-layout",
      name: "vertical-layout",
      component: () => import("@/view/main/layouts/page-layouts/vertical-layout"),
    },
    {
      path: "/main/layouts/page-layouts/horizontal-layout",
      name: "horizontal-layout",
      component: () => import("@/view/main/layouts/page-layouts/horizontal-layout"),
      meta: {
        layout: "horizontal",
      },
    },
    {
      path: "/main/layouts/page-layouts/full-layout",
      name: "full-layout",
      component: () => import("@/view/main/layouts/page-layouts/full-layout"),
      meta: {
        layout: "full",
      },
    },

    // PAGES
    {
      path: "/pages/error-403",
      name: "error-403",
      component: () => import("@/view/pages/errors/403"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/error-404",
      name: "error-404",
      component: () => import("@/view/pages/errors/404"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/error-500",
      name: "error-500",
      component: () => import("@/view/pages/errors/500"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/error-502",
      name: "error-502",
      component: () => import("@/view/pages/errors/502"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/error-503",
      name: "error-503",
      component: () => import("@/view/pages/errors/503"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/coming-soon",
      name: "coming-soon",
      component: () => import("@/view/pages/errors/coming-soon"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/maintenance",
      name: "maintenance",
      component: () => import("@/view/pages/errors/maintenance"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/pricing",
      name: "pricing",
      component: () => import("@/view/pages/pricing"),
      meta: {
        title: "Pricing",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/personel-information",
      name: "profile-password-change",
      component: () => import("../view/pages/profile/PersonelInformation.vue"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/notifications",
      name: "profile-notifications",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/activity",
      name: "profile-activity",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/security",
      name: "profile-security",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/password-change",
      name: "profile-password-change",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/profile/connect-with-social",
      name: "profile-connect-with-social",
      component: () => import("@/view/pages/profile"),
      meta: {
        title: "Profile",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/invoice",
      name: "invoice",
      component: () => import("@/view/pages/invoice"),
      meta: {
        title: "Invoice",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/faq",
      name: "faq",
      component: () => import("@/view/pages/faq"),
      meta: {
        title: "FAQ",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/applications/knowledge-base/knowledge-base-1",
      name: "knowledge-base-1",
      component: () => import("@/view/pages/knowledge-base/knowledge-base-1"),
      meta: {
        title: "Knowledge Base-1",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/applications/knowledge-base/knowledge-base-2",
      name: "knowledge-base-2",
      component: () => import("@/view/pages/knowledge-base/knowledge-base-2"),
      meta: {
        title: "Knowledge Base-2",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    {
      path: "/pages/blank-page",
      name: "blank-page",
      component: () => import("@/view/pages/blank"),
      meta: {
        title: "Blank Page",
        breadcrumb: [
          {
            text: "Pages"
          }
        ]
      },
    },
    // {
    //   path: "/pages/authentication/login",
    //   name: "login",
    //   component: () => import("@/view/pages/authentication/login"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    // {
    //   path: "/pages/authentication-modern/login",
    //   name: "login-modern",
    //   component: () => import("@/view/pages/authentication-modern/login"),
    //   meta: {
    //     layout: "full",
    //   },
    // },
    {
      path: "/pages/authentication/register",
      name: "register",
      component: () => import("@/view/pages/authentication/register"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication-modern/register",
      name: "register-modern",
      component: () => import("@/view/pages/authentication-modern/register"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/recover-password",
      name: "recover",
      component: () => import("@/view/pages/authentication/recover"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication-modern/recover-password",
      name: "recover-modern",
      component: () => import("@/view/pages/authentication-modern/recover"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication/reset-password",
      name: "reset",
      component: () => import("@/view/pages/authentication/reset"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/authentication-modern/reset-password",
      name: "reset-modern",
      component: () => import("@/view/pages/authentication-modern/reset"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/welcome",
      name: "welcome",
      component: () => import("@/view/pages/lock-screen/welcome"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/deactivated",
      name: "deactivated",
      component: () => import("@/view/pages/lock-screen/deactivated"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/password-is-changed",
      name: "password-is-changed",
      component: () => import("@/view/pages/lock-screen/password"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/pages/lock",
      name: "lock",
      component: () => import("@/view/pages/lock-screen/lock"),
      meta: {
        layout: "full",
      },
    },

    // COMPONENTS
    {
      path: "/components/components-page",
      name: "components-page",
      meta: {
        title: "Components",
      },
      component: () => import("@/view/components/components-page")
    },
    {
      path: "/components/general/remix-icons",
      name: "remix-icons",
      meta: {
        title: "Remix Icons",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "General"
          }
        ]
      },
      component: () => import("@/view/components/general/remix-icons")
    },
    {
      path: "/components/general/iconly-icons",
      name: "iconly-icons",
      meta: {
        title: "Iconly Icons",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "General"
          }
        ]
      },
      component: () => import("@/view/components/general/iconly-icons")
    },
    {
      path: "/components/general/buttons",
      name: "buttons",
      meta: {
        title: "Buttons",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "General"
          }
        ]
      },
      component: () => import("@/view/components/general/buttons")
    },
    {
      path: "/components/general/style-guide",
      name: "style-guide",
      meta: {
        title: "Style Guide",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "General"
          }
        ]
      },
      component: () => import("@/view/components/general/style-guide")
    },

    {
      path: "/components/navigation/menu",
      name: "menu",
      meta: {
        title: "Menu",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Navigation"
          }
        ]
      },
      component: () => import("@/view/components/navigation/menu")
    },
    {
      path: "/components/navigation/dropdown",
      name: "dropdown",
      meta: {
        title: "Dropdown",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Navigation"
          }
        ]
      },
      component: () => import("@/view/components/navigation/dropdown")
    },
    {
      path: "/components/navigation/breadcrumb",
      name: "breadcrumb",
      meta: {
        title: "Breadcrumb",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Navigation"
          }
        ]
      },
      component: () => import("@/view/components/navigation/breadcrumb")
    },
    {
      path: "/components/navigation/pagination",
      name: "pagination",
      meta: {
        title: "Pagination",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Navigation"
          }
        ]
      },
      component: () => import("@/view/components/navigation/pagination")
    },

    {
      path: "/components/data-entry/checkbox",
      name: "checkbox",
      meta: {
        title: "Checkbox",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/checkbox")
    },
    {
      path: "/components/data-entry/form",
      name: "form",
      meta: {
        title: "Form",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/form")
    },
    {
      path: "/components/data-entry/input",
      name: "input",
      meta: {
        title: "Input",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/input")
    },
    {
      path: "/components/data-entry/input-number",
      name: "input-number",
      meta: {
        title: "Input Number",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/input-number")
    },
    {
      path: "/components/data-entry/input-group",
      name: "input-group",
      meta: {
        title: "Input Group",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/input-group")
    },
    {
      path: "/components/data-entry/radio",
      name: "radio",
      meta: {
        title: "Radio",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/radio")
    },
    {
      path: "/components/data-entry/select",
      name: "select",
      meta: {
        title: "Select",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/select")
    },
    {
      path: "/components/data-entry/slider",
      name: "slider",
      meta: {
        title: "Slider",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/slider")
    },
    {
      path: "/components/data-entry/switch",
      name: "switch",
      meta: {
        title: "Switch",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Entry"
          }
        ]
      },
      component: () => import("@/view/components/data-entry/switch")
    },

    {
      path: "/components/data-display/avatar",
      name: "avatar",
      meta: {
        title: "Avatar",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/avatar")
    },
    {
      path: "/components/data-display/badge",
      name: "badge",
      meta: {
        title: "Badge",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/badge")
    },
    {
      path: "/components/data-display/card",
      name: "card",
      meta: {
        title: "Card",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/card")
    },
    {
      path: "/components/data-display/carousel",
      name: "carousel",
      meta: {
        title: "Carousel",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/carousel")
    },
    {
      path: "/components/data-display/accordion",
      name: "accordion",
      meta: {
        title: "Accordion",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/accordion")
    },
    {
      path: "/components/data-display/collapse",
      name: "collapse",
      meta: {
        title: "Collapse",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/collapse")
    },
    {
      path: "/components/data-display/list",
      name: "list",
      meta: {
        title: "List",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/list")
    },
    {
      path: "/components/data-display/popover",
      name: "popover",
      meta: {
        title: "Popover",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/popover")
    },
    {
      path: "/components/data-display/table",
      name: "table",
      meta: {
        title: "Table",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/table")
    },
    {
      path: "/components/data-display/tabs",
      name: "tabs",
      meta: {
        title: "Tabs",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/tabs")
    },
    {
      path: "/components/data-display/tooltip",
      name: "tooltip",
      meta: {
        title: "Tooltip",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Data Display"
          }
        ]
      },
      component: () => import("@/view/components/data-display/tooltip")
    },
    {
      path: "/components/feedback/alert",
      name: "alert",
      meta: {
        title: "Alert",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/alert")
    },
    {
      path: "/components/feedback/drawer",
      name: "drawer",
      meta: {
        title: "Drawer",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/drawer")
    },
    {
      path: "/components/feedback/modal",
      name: "modal",
      meta: {
        title: "Modal",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/modal")
    },
    {
      path: "/components/feedback/notification",
      name: "notification",
      meta: {
        title: "Notification",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/notification")
    },
    {
      path: "/components/feedback/progress",
      name: "progress",
      meta: {
        title: "Progress",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/progress")
    },
    {
      path: "/components/feedback/spinner",
      name: "spinner",
      meta: {
        title: "Spinner",
        breadcrumb: [
          {
            text: "Components",
            path: "/components/components-page"
          },
          {
            text: "Feedback"
          }
        ]
      },
      component: () => import("@/view/components/feedback/spinner")
    },
    {
      path: '*',
      component: () => import("@/view/pages/errors/404"),
      meta: {
        layout: "full",
      },
    },
  ]
});
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = localStorage.getItem("token");
    if (!token) {
      // User is not authenticated, redirect to login page
      next({ name: 'login' });
    } else {
      // User is authenticated, allow navigation
      next();
    }
  } else {
    // Route doesn't require authentication, allow navigation
    next();
  }
});

export default router