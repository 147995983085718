export default {
  // header: "Dashboards",
  children: [
    {
      id: "/dashboard",
      title: "Dashboard",
      iconSvg: '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"><path d="M8.97 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7h-6c-5 0-7 2-7 7v6c0 5 2 7 7 7Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="m1.97 12.7 6-.02c.75 0 1.59.57 1.87 1.27l1.14 2.88c.26.65.67.65.93 0l2.29-5.81c.22-.56.63-.58.91-.05l1.04 1.97c.31.59 1.11 1.07 1.77 1.07h4.06" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>',
      navLink: "/dashboards",
    },
      
    // Divers code here 
    {
      id: "request",
      title: "Request",
      icon: "Curved-Graph",
      
      children: [
       
        {
          id: "request",
          title: "View Request",
          navLink: "/request/list",
        },
       
      ],
      
    },
    // User code here 
    {
      id: "user",
      title: "User",
      icon: "Curved-AddUser",
      
      children: [
       
        {
          id: "user",
          title: "List User",
          navLink: "/view/list/user",
        },
      ],
      
    },
     // Vehicle code here 
     {
      id: "vehicle",
      title: "Vehicle",
      icon: "Curved-TicketStar",
      
      children: [
        {
          id: "vehicle",
          title: "Add Vehicle",
          navLink: "/add/vehicle",
        },
        {
          id: "vehicle",
          title: "List Vehicle",
          navLink: "/list/vehicle",
        }, 
        {
          id: "vehicle",
          title: "List Theft",
          navLink: "/list/theft",
        },  
        {
          id: "vehicle",
          title: "Recover Vehicle",
          navLink: "/recover/vehicle/theft/list",
        },
      ],
    },
      // Category code here 
      {
        id: "Category",
        title: "Vehicle Categories",
        icon: "Curved-Document",  
        children: [
          {
            id: "Category",
            title: "Add Category",
            navLink: "/add/category",
          },
          {
            id: "Category",
            title: "List Category",
            navLink: "/list/category",
          }, 
        ],
      },
      // Brand code here 
      {
        id: "Brand",
        title: "Vehicle Brands",
        icon: "Curved-Discount",
        
        children: [
          {
            id: "Brand",
            title: "Add Brand",
            navLink: "/add/brand",
          },
          {
            id: "Brand",
            title: "List Brand",
            navLink: "/list/brand",
          }, 
        ],
      },


            // Model code here 
            {
              id: "Model",
              title: "Vehicle Model",
              icon: "Curved-Paper",
              
              children: [
                {
                  id: "Model",
                  title: "Add Model",
                  navLink: "/add/model",
                },
                {
                  id: "Model",
                  title: "List Model",
                  navLink: "/list/model",
                }, 
              ],
            },
           
           // Insurance company code here 
           {
            id: "Insurancecompany",
            title: "Insurance company",
            icon: "Curved-Message",
            
            children: [
              {
                id: "Insurancecompany",
                title: "Add Insurance company",
                navLink: "/add/insurance/company",
              },
              {
                id: "Insurancecompany",
                title: "List Insurance company",
                navLink: "/list/insurance/company",
              }, 
            ],
          },    
      // MarketPlace code here 
      {
        id: "MarketPlace",
        title: "MarketPlace",
        icon: "Curved-InfoSquare",
        
        children: [
          {
            id: "MarketPlace",
            title: "Add MarketPlace",
            navLink: "/add/marketplace",
          },
          {
            id: "MarketPlace",
            title: "List MarketPlace",
            navLink: "/list/marketplace",
          }, 
        ],
        
      },
         // Police code here 
         {
          id: "Slider",
          title: "Slider",
          icon: "Curved-Discovery",
          
          children: [
            {
              id: "Slider",
              title: "Add Slider",
              navLink: "/add/slider",
            },
            {
              id: "Slider",
              title: "List Slider",
              navLink: "/list/slider",
            }, 
          ],
          
        },
       // Police code here 
       {
        id: "police",
        title: "Police",
        icon: "Curved-AddUser",
        
        children: [
          {
            id: "police",
            title: "Add Police",
            navLink: "/add/police",
          },
          {
            id: "police",
            title: "List Police",
            navLink: "/list/police",
          }, 
        ],
        
      },  
       // Notication code  here 
       {
        id: "Notification",
        title: "Notification",
        icon: "Curved-Notification",
        
        children: [
        
            {
            id: "Notification",
            title: "Detail Notification",
            navLink: "/notification/detail",
          },
           
        ],
      },
          // Branch code here 
          {
            id: "InspectionLocation",
            title: "Inspection Location",
            icon: "Curved-EditSquare",
            
            children: [
              {
                id: "InspectionLocation",
                title: "Add Inspection Location",
                navLink: "/add/branch",
              },
              {
                id: "InspectionLocation",
                title: "List Inspection Location",
                navLink: "/list/branch",
              }, 
            ],
            
          },
            // Schedule code here 
            {
              id: "Schedule",
              title: "Schedule",
              icon: "Curved-Filter2",
              
              children: [
                
                {
                  id: "Schedule",
                  title: "List Schedule",
                  navLink: "/list/schedule",
                }, 
              ],
              
            },
  ]
}
